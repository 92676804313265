.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contacts .right {
  padding: 50px;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}
.contacts p {
  color: grey;
}
.contacts .items {
  margin: 30px 0;
}
.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
}
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contacts span {
  color: #1eb2a6;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 50vh;
  }
  .contacts .row {
    width: 100%;
  }
}
.gunwi:hover,
.dalseong:hover path,
.donggu:hover,
.seogu:hover,
.namgu:hover,
.bukgu:hover,
.junggu:hover,
.dalseogu:hover,
.suseonggu:hover {
  fill: rgba(30, 178, 166,0.5) !important;
  cursor: pointer;
}

.blog img {
  width: 100%;
  height: 100%;
}
.blog .text {
  padding: 40px 30px;
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.demoitems:hover h1 {
  color: #1eb2a6;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #1eb2a6;
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: #1eb2a6;
}
.blog p {
  color: grey;
}
.demoitems {
  /* 마우스를 오버했을 때 외곽선 스타일을 지정합니다. */
  transition: box-shadow 0.3s;
  cursor: pointer;
}

.demoitems:hover {
  /* 마우스를 오버했을 때 외곽선 스타일을 강조합니다. */
  box-shadow: 0px 0px 10px 3px #ccc;
}
